<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu menu="event"></company-submenu>

      <div class="submenu-content">
        <div v-for="items in events" class="clearfix">
          <div class="lead fs-2 mb-3 border-bottom">{{ items.year }} Events</div>
          <div class="mb-5 clearfix">
          <ul>
            <div>
              <li v-for="item in items.data" :key="item.id">
                <div class="block">
                  <div v-if="item.external_link">
                    <router-link :to="item.external_link" target="_blank" class="logo">
                        <img v-if="item.logo" :src="item.logo.meta.download_url"
                        >
                    </router-link>
                  </div>
                  <div v-else>
                    <router-link :to="'/company/events/'+item.slug" class="logo">
                        <img v-if="item.logo" :src="item.logo.meta.download_url"
                        >
                    </router-link>
                  </div>
                    
                    <div class="row">           
                      <div v-if="item.external_link">
                        <router-link :to="item.external_link" target="_blank">{{ item.title }}</router-link>   
                      </div>         
                      <div v-else>
                        <router-link :to="'/company/events/'+item.slug">{{ item.title }}</router-link>   
                      </div>
                                         
                      <div class="innerp" v-if="item.location"> {{item.location}}</div>
                      <div v-if="item.dates">{{item.dates}}</div>
                      <div v-else>{{item.date}}</div>
                      <div v-if="item.address" v-html="item.address" class="address"></div>
                    </div>
                </div>
              </li>
            </div>
          </ul>
          </div>
        </div>


        
      </div>

  </div>
</template>

<script>
import CompanySubmenu from "../../components/nav/CompanySubmenu.vue"
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { getAPI } from '../../utils/axios-api'
import { ref, onMounted } from "vue";

export default {
  name: "Events",
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    const item = ref({});
    const events = ref([])

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Events", link: "Events"})


    getAPI
    .get("content/events/?limit=1000&status=Active&order=-date")
    .then((response) => {
      
      let items = {}
      let prev_year = 0
      let cur_year = 0
      for (let i = 0; i < response.data.items.length; i++) {
        cur_year = response.data.items[i].year
console.log("---", response.data.items[i])
        if (items.hasOwnProperty(cur_year) == false) {
          items[cur_year]= []
        }
        prev_year = cur_year
        items[cur_year].push(response.data.items[i])
      }

      //sort reverse
      var sorted = [];
      for(var key in items) {
          sorted[sorted.length] = key;
      }
      sorted.sort();
      sorted.reverse();
      for (let i=0; i < sorted.length; i++) {
        events.value.push({"year": sorted[i], "data": items[sorted[i]]})
      }
      
    })

    return { item, events, breadcrumb_links };
  },
};
</script>

<style scoped>
  .submenu-content h2 {
    float:none;
    clear:both;
    border-top: 1px solid #ccc;
    padding-top:15px;
  }
  .submenu-content div:nth-child(1) h2 {
    border-top: 0;
  }

  .submenu-content ul {list-style:none;}
  .submenu-content ul li {float: left; width: 100%;}
  .submenu-content ul li div {padding-right: 30px; padding-bottom: 50px;}
  .submenu-content ul li div.block {min-height:160px;}
  @media (min-width: 1035px) {
    .submenu-content ul > div {padding-bottom: 30px;}
    .submenu-content ul li {width: 50%; margin-bottom:20px;}
    .submenu-content ul li div {padding-bottom: 0;}
  }
  .submenu-content ul li a.logo {display:block; float:left; width:100px; height:100px;}
  .submenu-content ul li img {float: left; width:100px;}
  .submenu-content ul p {margin-left: 110px;}
  .submenu-content ul div.innerp p {margin-left: 0}
  .submenu-content ul li span {font-weight:bold;}
  .submenu-content ul li a {color: #333; text-decoration: none; font-weight: bold;}
  .submenu-content ul li a:hover {text-decoration: underline; color:dodgerblue;}

  .address {
    line-height: 10px;
    margin-top:10px;
  }
</style>
